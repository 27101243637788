
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as formulaire0HzTLgJZnNMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue?macro=true";
import { default as indexsOlblmREVNMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue?macro=true";
import { default as _91k_93YVr0mEq1G7Meta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue?macro=true";
import { default as acteursQNtssjN6rTMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/acteurs.vue?macro=true";
import { default as configurationEYgpn58uGOMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/configuration.vue?macro=true";
import { default as formulairePkpWJW6x26Meta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue?macro=true";
import { default as indexJwe3aOOZpXMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue?macro=true";
import { default as _91k_93xCum69yAFgMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue?macro=true";
import { default as territoirestBhOfPXRhzMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/territoires.vue?macro=true";
import { default as utilisateurs_45permissionsOUGT6INp3LMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/utilisateurs-permissions.vue?macro=true";
import { default as formulaire3LpgZMKIzlMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue?macro=true";
import { default as indexCMzocQ3cRjMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue?macro=true";
import { default as _91k_93AqonpBUxrXMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue?macro=true";
import { default as utilisateursIOYoSgzEYrMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue?macro=true";
import { default as indexAdxTxHtSw4Meta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/connexion/index.vue?macro=true";
import { default as _91token_93vKdJRHV7iyMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue?macro=true";
import { default as indexmXVCjG2ZOYMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oublieGYCP0WgsEfMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue?macro=true";
import { default as contactfm2FtdUxBsMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/contact.vue?macro=true";
import { default as donnees_45personnellesentW2y6yNKMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/donnees-personnelles.vue?macro=true";
import { default as _91k_93jLknHZieBuMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/download/[k].vue?macro=true";
import { default as erreurBtK5P6G5eqMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/erreur.vue?macro=true";
import { default as formulaireuYXCwZrHiKMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue?macro=true";
import { default as indexLGXd9FiQb8Meta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue?macro=true";
import { default as _91k_93iT5JBpBDqmMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue?macro=true";
import { default as controles_45simultanesW6Dj7ilL4VMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue?macro=true";
import { default as formulaireYd3CrlvxsgMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue?macro=true";
import { default as index2ZgnwxwrvfMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue?macro=true";
import { default as _91k_93L2EemZEctKMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue?macro=true";
import { default as envoi_45de_45mailAxDeeTbihxMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue?macro=true";
import { default as historiqueN12dhWjHRpMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/historique.vue?macro=true";
import { default as formulairewU8TogCwA7Meta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue?macro=true";
import { default as indexzwMrPtSsYZMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue?macro=true";
import { default as _91k_93UGDEyDzajkMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/import/[k].vue?macro=true";
import { default as importUShdb7PJDgMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/import.vue?macro=true";
import { default as formulaireUlLQ82SEijMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue?macro=true";
import { default as index6CeUwPVUWjMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue?macro=true";
import { default as _91k_93VtroApHRsnMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue?macro=true";
import { default as indisponibilites_45programmeesqba3YHq5fqMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue?macro=true";
import { default as formulaire4gFu0p77P9Meta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue?macro=true";
import { default as indexq7S3vxRZKrMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue?macro=true";
import { default as _91k_93GrsXx4ZxnBMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue?macro=true";
import { default as peiwf93ZoOVS4Meta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/pei.vue?macro=true";
import { default as formulaire5aG6N4MiZ1Meta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue?macro=true";
import { default as indexhoxirNRnEUMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue?macro=true";
import { default as _91k_93mGOl8Z06aCMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue?macro=true";
import { default as tourneesLFH6QwvcFwMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/tournees.vue?macro=true";
import { default as indexu7U7OmZYcKMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/index.vue?macro=true";
import { default as index0b11DqZh7rMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/profil/index.vue?macro=true";
import { default as profilPEuhHriH37Meta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/profil.vue?macro=true";
import { default as ressourcesoE2gpXk02pMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/ressources.vue?macro=true";
import { default as tableau_45de_45bord133DW9NNfEMeta } from "/builds/9527656145/hydraclic-v2/nuxt/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: "administration-acteurs",
    path: "/administration/acteurs",
    meta: acteursQNtssjN6rTMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/acteurs.vue"),
    children: [
  {
    name: _91k_93YVr0mEq1G7Meta?.name,
    path: ":k()",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue"),
    children: [
  {
    name: "administration-acteurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue")
  },
  {
    name: "administration-acteurs-k",
    path: "",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-configuration",
    path: "/administration/configuration",
    meta: configurationEYgpn58uGOMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/configuration.vue")
  },
  {
    name: "administration-territoires",
    path: "/administration/territoires",
    meta: territoirestBhOfPXRhzMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/territoires.vue"),
    children: [
  {
    name: _91k_93xCum69yAFgMeta?.name,
    path: ":k()",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue"),
    children: [
  {
    name: "administration-territoires-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue")
  },
  {
    name: "administration-territoires-k",
    path: "",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-utilisateurs-permissions",
    path: "/administration/utilisateurs-permissions",
    meta: utilisateurs_45permissionsOUGT6INp3LMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/utilisateurs-permissions.vue")
  },
  {
    name: "administration-utilisateurs",
    path: "/administration/utilisateurs",
    meta: utilisateursIOYoSgzEYrMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue"),
    children: [
  {
    name: _91k_93AqonpBUxrXMeta?.name,
    path: ":k()",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue"),
    children: [
  {
    name: "administration-utilisateurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue")
  },
  {
    name: "administration-utilisateurs-k",
    path: "",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "connexion",
    path: "/connexion",
    meta: indexAdxTxHtSw4Meta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/connexion/index.vue")
  },
  {
    name: mot_45de_45passe_45oublieGYCP0WgsEfMeta?.name,
    path: "/connexion/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oublieGYCP0WgsEfMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue"),
    children: [
  {
    name: "connexion-mot-de-passe-oublie-token",
    path: ":token()",
    meta: _91token_93vKdJRHV7iyMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue")
  },
  {
    name: "connexion-mot-de-passe-oublie",
    path: "",
    meta: indexmXVCjG2ZOYMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/contact.vue")
  },
  {
    name: "donnees-personnelles",
    path: "/donnees-personnelles",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/donnees-personnelles.vue")
  },
  {
    name: "download-k",
    path: "/download/:k()",
    meta: _91k_93jLknHZieBuMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/download/[k].vue")
  },
  {
    name: "erreur",
    path: "/erreur",
    meta: erreurBtK5P6G5eqMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/erreur.vue")
  },
  {
    name: "gestion-controles-simultanes",
    path: "/gestion/controles-simultanes",
    meta: controles_45simultanesW6Dj7ilL4VMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue"),
    children: [
  {
    name: _91k_93iT5JBpBDqmMeta?.name,
    path: ":k()",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue"),
    children: [
  {
    name: "gestion-controles-simultanes-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue")
  },
  {
    name: "gestion-controles-simultanes-k",
    path: "",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-envoi-de-mail",
    path: "/gestion/envoi-de-mail",
    meta: envoi_45de_45mailAxDeeTbihxMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue"),
    children: [
  {
    name: _91k_93L2EemZEctKMeta?.name,
    path: ":k()",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue"),
    children: [
  {
    name: "gestion-envoi-de-mail-k-formulaire",
    path: "formulaire",
    meta: formulaireYd3CrlvxsgMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue")
  },
  {
    name: "gestion-envoi-de-mail-k",
    path: "",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-historique",
    path: "/gestion/historique",
    meta: historiqueN12dhWjHRpMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/historique.vue")
  },
  {
    name: "gestion-import",
    path: "/gestion/import",
    meta: importUShdb7PJDgMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/import.vue"),
    children: [
  {
    name: _91k_93UGDEyDzajkMeta?.name,
    path: ":k()",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/import/[k].vue"),
    children: [
  {
    name: "gestion-import-k-formulaire",
    path: "formulaire",
    meta: formulairewU8TogCwA7Meta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue")
  },
  {
    name: "gestion-import-k",
    path: "",
    meta: indexzwMrPtSsYZMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-indisponibilites-programmees",
    path: "/gestion/indisponibilites-programmees",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue"),
    children: [
  {
    name: _91k_93VtroApHRsnMeta?.name,
    path: ":k()",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue"),
    children: [
  {
    name: "gestion-indisponibilites-programmees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue")
  },
  {
    name: "gestion-indisponibilites-programmees-k",
    path: "",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-pei",
    path: "/gestion/pei",
    meta: peiwf93ZoOVS4Meta || {},
    alias: ["/gestion/carte"],
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/pei.vue"),
    children: [
  {
    name: _91k_93GrsXx4ZxnBMeta?.name,
    path: ":k()",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue"),
    children: [
  {
    name: "gestion-pei-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue")
  },
  {
    name: "gestion-pei-k",
    path: "",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-tournees",
    path: "/gestion/tournees",
    meta: tourneesLFH6QwvcFwMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/tournees.vue"),
    children: [
  {
    name: _91k_93mGOl8Z06aCMeta?.name,
    path: ":k()",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue"),
    children: [
  {
    name: "gestion-tournees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue")
  },
  {
    name: "gestion-tournees-k",
    path: "",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/index.vue")
  },
  {
    name: profilPEuhHriH37Meta?.name,
    path: "/profil",
    meta: profilPEuhHriH37Meta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/profil.vue"),
    children: [
  {
    name: "profil",
    path: "",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/profil/index.vue")
  }
]
  },
  {
    name: "ressources",
    path: "/ressources",
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/ressources.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    meta: tableau_45de_45bord133DW9NNfEMeta || {},
    component: () => import("/builds/9527656145/hydraclic-v2/nuxt/pages/tableau-de-bord.vue")
  }
]