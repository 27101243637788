import validate from "/builds/9527656145/hydraclic-v2/nuxt/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/builds/9527656145/hydraclic-v2/nuxt/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/9527656145/hydraclic-v2/nuxt/middleware/auth.ts"),
  "check-is-user": () => import("/builds/9527656145/hydraclic-v2/nuxt/middleware/checkIsUser.ts"),
  "has-territory": () => import("/builds/9527656145/hydraclic-v2/nuxt/middleware/hasTerritory.ts")
}